.about-hero {
    display: flex;
    flex-direction: column;
    background-image: url("../images/studio-hero.jpeg");
    /* Set padding instead of fixed height */
    padding: 20px; /* Add some padding to the section */
    height: 60vh;
    background-repeat: repeat;
    background-size: cover;
    background-attachment: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative; /* Ensure container allows for precise positioning */
    margin: 0; 
  }

  .about-hero-title {
    color: white;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    font-weight: bold;
    font-size: 88px;
    opacity: 60%;
    margin-top: -80px;
  }

  .touring-container {
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Default to horizontal layout */
    justify-content: space-evenly; /* Space between columns */
    align-items: flex-start; /* Align items to the top */
    width: 100%; /* Full width of the section */
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box; /* Include padding in width calculation */
}

.image-column {
    flex: 1; /* Allow the image column to take 1 part of the space */
    max-width: 50%; /* Limit to half the width */
}

.touring-image {
    width: 80%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
}

.paragraphs-column {
    flex: 1; /* Allow the text column to take 1 part of the space */
    max-width: 40%; /* Limit to half the width */
    padding-left: 5px; /* Space between the image and text */
    /* padding-top: 40px; */
}

.touring-subtitle {
    font-size: 24px; /* Subtitle font size */
    margin-bottom: 10px; /* Space below the subtitle */
}

.touring-paragraph-1,
.touring-paragraph-2,
.touring-paragraph-3 {
    margin-bottom: 10px;
    /* font-family: ; */
    font-size: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .touring-container {
        flex-direction: column; /* Stack columns vertically */
        align-items: center; /* Center items */
    }

    .image-column, .paragraphs-column {
        max-width: 100%; /* Full width on small screens */
        padding-left: 0; /* Remove left padding */
    }

    .touring-image {
        margin-bottom: 20px; /* Space between image and text on mobile */
    }
}
.about-logos-container {
    display: flex; /* Use flex for better alignment */
    justify-content: center; /* Center logos */
    align-items: center; /* Center vertically */
    background-image: url("../images/projects-background.png");
    height: 20vh; /* Height of the section */
    background-repeat: no-repeat;
    background-size: cover; /* Cover the whole section */
    background-attachment: fixed;
    background-position: center; /* Center the background image */
    width: 100%; /* Full width of the section */
    position: relative; /* Position relative for proper layering */
    padding: 20px; /* Add padding to avoid content touching edges */
    margin: 0; 
}

/* New wrapper to manage logos */
.logos-wrapper {
    width: 100%; /* Full width */
    max-width: 1200px; /* Limit max width to prevent overspill */
    overflow: hidden; /* Prevent content from spilling out */
}

.about-logos-container ul {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    display: flex; /* Use flexbox for alignment */
    justify-content: space-around; /* Space logos evenly */
    align-items: center; /* Align logos vertically in the center */
    margin: 0; /* Remove default margin */
}

.about-logos-container li {
    flex: 0 1 auto; /* Allow items to flex, but not grow */
    margin: 0 10px; /* Add space between logos */
}

.logos-image {
    max-width: 100%; /* Ensure images are responsive */
    height: auto; /* Maintain aspect ratio */
}


.background-container {
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Default to horizontal layout */
    justify-content: space-evenly; /* Space between columns */
    align-items: flex-start; /* Align items to the top */
    width: 100%; /* Full width of the section */
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box; /* Include padding in width calculation */
}

.paragraph-column {
    flex: 1; /* Allow the text column to take 1 part of the space */
    max-width: 40%; /* Limit to half the width */
    text-align: center;
    /* padding-top: 100px; */
}

.background-subtitle {
    font-size: 24px; /* Subtitle font size */
    margin-bottom: 20px; /* Reduced space below the subtitle */
}

.background-paragraph-1,
.background-paragraph-2 {
    margin-bottom: 20px; 
    font-size: 20px;
}

.image-column {
    flex: 1; /* Allow the image column to take 1 part of the space */
    max-width: 40%; /* Limit to half the width */
}

.backgroundCollage {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .background-container {
        flex-direction: column; /* Stack columns vertically */
        align-items: center; /* Center items */
    }

    .paragraph-column, .image-column {
        max-width: 100%; /* Full width on small screens */
        /* padding-left: 30px; Remove left padding */
    }

    .backgroundCollage {
        margin-bottom: 10px; /* Space between image and text on mobile */
    }
}
.about-get-in-touch-container{
    display: flex-start;
    flex-direction: column;
    background-image: url("../images/skanda-contact.jpeg");
    height: 80vh;
    align-self: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    width: 100%;
    position: relative; /* Ensure container allows for precise positioning */
    padding-left: 50px; /* Remove default padding */
    margin: 0; 
    filter: grayscale(100%); /* Apply grayscale to the background */

   
  }
  .about-get-in-touch-title-continer {
    text-align: left;
    position: relative; 
    width: 250px;
    top: 30px; 
    padding: 0; 
    margin: 0; 
    
  }

  .about-get-in-touch-title {
    text-align: left;
    color: white;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    font-size: 100px;
    opacity: 80%;
    padding: 0; 
    margin: 0;
  }

  .email-address{
    font-family: "Bebas Neue", sans-serif;
    font-size:80px;
    color: white;
    opacity: 80%;

  }

/* For Tablets and Smaller Screens */
@media (max-width: 1024px) {
    .index-hero-title {
      font-size: 60px; /* Adjust font size for the title */
    }

    .projects-container-title {
      font-size: 60px; /* Adjust project title size */
    }

    .projects-subtitle {
      font-size: 18px; /* Adjust subtitle text size */
    }

    .about-get-in-touch-title {
      font-size: 60px; /* Adjust 'Get in Touch' title size */
    }

    .working-with-us-container {
      padding: 20px; /* Add padding for better spacing */
    }
}

/* For Mobile Devices */
@media (max-width: 600px) {
    /* Center hero content for mobile */
    .index-hero {
      height: 100vh;
      align-items: center;
      justify-content: center;
      padding: 10px; /* Add padding to reduce content width */
    }

    .index-hero-title-container {
      text-align: center; /* Center content */
    }

    .index-hero-title {
      font-size: 40px; /* Smaller font size for mobile */
    }

    .index-hero-title-top,
    .index-hero-title-bottom {
      font-size: 16px; /* Smaller subtitles */
      margin: 10px;
    }

    .projects-container {
      height: auto; /* Remove fixed height */
      padding: 20px; /* Add padding to reduce content width */
    }

    .projects-container-title,
    .about-get-in-touch-title {
      font-size: 40px; /* Smaller font size */
      text-align: center; /* Center content */
    }

    .projects-subtitle-container {
      top: 100px; /* Adjust position */
      left: 0%;
      transform: translateX(0); /* Remove horizontal shift */
    }

    .projects-subtitle {
      font-size: 16px; /* Smaller font size */
      text-align: center;
      margin: 10px;
      padding: 10px;
    }

    .working-with-us-container {
      height: auto;
      padding: 10px;
    }

    .working-with-us-title-continer,
    .about-get-in-touch-title-continer {
      text-align: center; /* Center for mobile */
      padding: 20px;
    }

    .working-with-us-paragraphs-container,
    .about-get-in-touch-title-continer {
      padding: 10px;
    }

    .working-with-us-paragraph {
      font-size: 14px;
      line-height: 1.6;
    }

    .about-get-in-touch-title {
      font-size: 66px;
    }

    .email-address{
        font-family: "Bebas Neue", sans-serif;
        font-size:30px;
        color: white;
        opacity: 100%;
        
      }
}