/* Projects Hero Section */
.projects-hero {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 70vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin: 0;
    overflow: hidden; /* Ensure the pseudo-element is contained */
  }
  
  .projects-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/projects-background.png");
    background-repeat: repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: -1; /* Position it behind the main content */
    filter: sepia(80%); /* Apply the sepia filter */
    transform: scaleX(-1); /* Flip the background image horizontally */
    opacity: 75%; /* Ensure full visibility */
  }
  
  .projects-hero-title {
    color: white;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    font-size: 88px;
    opacity: 95%;
  }
  
  /* Main Video Container */
  .all-videos {
    display: flex;
    flex-direction: column;
    background-image: url("../images/projects-background.png");
    align-self: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    width: 100%;
    position: relative;
    padding: 30px 20px;
    margin: 0;
  }
  .video-grid-container {
    background-color: rgba(0, 0, 0, 0.8); /* 60% opacity */
  }
  
  /* Individual Video Sections */
  .videoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
  
  /* Video Slide-In Animations */
  .video-slide-in-left {
    transform: translateX(-50%);
    opacity: 0;
    transition: all 1s ease-out;
  }
  
  .video-slide-in-center {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease-out;
  }
  
  .video-slide-in-right {
    transform: translateX(50%);
    opacity: 0;
    transition: all 1s ease-out;
  }
  
  .active-left,
  .active-center,
  .active-right {
    transform: translateX(0);
    opacity: 1;
  }
  
  /* Grid Layout for Video Section */
  .video-wrapper {
    position: relative;
    width: 500px; /* Set the width for each video */
    height: auto; /* Let the height expand with content */
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    display: flex; /* Use flexbox to stack elements vertically */
    flex-direction: column; /* Stack title, video, and description in a column */
    justify-self: center; /* Center each video-wrapper within its column */
    align-items: center; /* Center align content */
    /* text-align: center; Center text content */
  }
  
  .video-wrapper h3 {
    align-self: flex-start; /* Align h3 to the top left */
    margin: 0; /* Remove any default margin */
    padding-bottom: 10px; /* Add some spacing between the title and the video */
    font-size: 24px; /* Adjust the font size as needed */
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: white;
  }
  
  .video-wrapper video {
    width: 100%; /* Make the video fill the container's width */
    max-height: 300px; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Add spacing between video and description */
  }
  
  .video-wrapper p {
    text-align: center; /* Center-align the paragraph */
    margin: 10px 0 0; 
    font-size: 16px;
    color: lightgrey; /* Light grey text for contrast */
  }
  
  /* Adjust the .video-grid to ensure even spacing and layout */
  .video-grid {
    display: grid; /* Use grid display for precise control */
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-gap: 20px; /* Even spacing between items */
    justify-content: center; /* Center the entire grid */
    padding: 30px;
  }

  .sound-toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
  }
  
  .sound-toggle-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Projects Logo Section */
  .projects-logos-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 20vh;
    align-self: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    width: 100%;
    position: relative;
    padding-left: 50px;
    margin: 0;
    padding-top: 30px;
  }
  
  /* Contact Section */
  .projects-get-in-touch-container {
    display: flex;
    flex-direction: column;
    background-image: url("../images/skanda-contact.jpeg");
    height: 80vh;
    align-self: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    width: 100%;
    position: relative;
    padding-left: 50px;
    margin: 0;
    filter: grayscale(100%);
  }
  
  .projects-get-in-touch-title-continer {
    text-align: left;
    position: relative;
    width: 250px;
    top: 30px;
    padding: 0;
    margin: 0;
  }
  
  .projects-get-in-touch-title {
    text-align: left;
    color: white;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    font-size: 100px;
    opacity: 80%;
    padding: 0;
    margin: 0;
  }
  
  .email-address{
    font-family: "Bebas Neue", sans-serif;
    font-size:80px;
    color: white;
    opacity: 80%;
  }

  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .projects-hero-title {
      font-size: 60px;
    }
  
    .projects-get-in-touch-title {
      font-size: 66px;
    }
    .video-grid {
        grid-template-columns: 1fr 1fr; /* Two columns for tablets */
      }
    
      .video-wrapper {
        width: 400px; /* Slightly smaller for tablets */
      }
  }
  
  @media (max-width: 600px) {
    .index-hero {
      height: 100vh;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  
    .projects-get-in-touch-title {
        font-size: 40px; /* Smaller font size */
        text-align: center; /* Center content */
        opacity: 100%;
  
    }
  
    .email-address{
        font-family: "Bebas Neue", sans-serif;
        font-size:30px;
        color: white;
        opacity: 100%;
        
      }
    .video-list {
      grid-template-columns: 1fr; /* Stack videos in one column for mobile */
    }
    .video-grid {
        grid-template-columns: 1fr; /* Single column layout for mobile */
      }
    
      .video-wrapper {
        width: 100%; /* Full width for mobile screens */
      }
  }